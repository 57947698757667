import React from 'react';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';
import AppContext from '../AppContext';
import Footer from '../components/layout/Footer';

const outerClasses = classNames(
  'hero section center-content'
);

const innerClasses = classNames(
  'hero-inner section-inner terms-of-condition'
);

const Terms = () => {
  return (
    <AppContext.Provider value={{}}>
      <section
        className={outerClasses}
      >
      <div className={innerClasses}>
        <h3><FormattedHTMLMessage id="terms" /></h3>
        <p className="updated"><i><FormattedHTMLMessage id="terms.date" /></i></p>
        <p><FormattedHTMLMessage id="terms.desc" /></p>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec1.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec1.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec2.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec2.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec3.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec3.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec4.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec4.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec5.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec5.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec6.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec6.p1" /></p>
            <p><FormattedHTMLMessage id="terms.sec6.p2" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec7.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec7.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec8.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec8.p1" /></p>
          </div>
        </div>
        {/* <div>
          <h4><FormattedHTMLMessage id="terms.sec9.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec9.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec10.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec10.p1" /></p>
          </div>
        </div> */}
        <div>
          <h4><FormattedHTMLMessage id="terms.sec11.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec11.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec12.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec12.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec13.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec13.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec14.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec14.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec15.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec15.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec16.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec16.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec17.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec17.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec18.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec18.p1" /></p>
          </div>
        </div>
        {/* <div>
          <h4><FormattedHTMLMessage id="terms.sec19.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec19.p1" /></p>
          </div>
        </div> */}
        <div>
          <h4><FormattedHTMLMessage id="terms.sec20.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec20.p1" /></p>
          </div>
        </div>
        <div>
          <h4><FormattedHTMLMessage id="terms.sec21.title" /></h4>
          <div>
            <p><FormattedHTMLMessage id="terms.sec21.p1" /></p>
          </div>
        </div>
      </div>
      </section>
      <Footer />
    </AppContext.Provider>
  );
}

export default Terms;