// import VConsole from 'vconsole';

import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
// import ReactGA from 'react-ga';
import 'react-notifications/lib/notifications.css';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Terms from './views/Terms';
import About from './views/About';
// import Roadmap from './views/Roadmap';
// import FAQs from './views/FAQs';
// import Owners from './views/Owners';
// import Giveaway from './views/Giveaway';
// import { getBlockchain } from './ethereum';

// Initialize Google Analytics
// ReactGA.initialize('G-WHH17WWE3J');

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

// const vConsole = new VConsole();
// console.log(vConsole);

const App = (props) => {

  const { startSales, startGiveaways, mobile } = props;

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();

    window.gtag('event', 'page_view', {
      page_title: page.replace('/', '').toUpperCase(),
      page_location: page.replace('/', ''),
      page_path: page,
      send_to: 'G-WHH17WWE3J'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // // Connect to Ethereum
  // useEffect(() => {
    
  //   const init = async () => {
  //     if (!startSales) return;
  //     // Read Contract
  //     try {
  //       const { readContract } = await getBlockchain();
  //       if (readContract) {
  //         setBabiesReadContract(readContract);
  //       }
  //     } catch(e) {
  //       console.error(e.message);
  //     }
  //   };
  //   init();

  // }, [location, startSales]);

  // // Update Contract Information
  // useEffect(() => {
  //   if (!startSales) return;
    
  //   const query = async () => {
  //     setGlitch(babiesReadContract && !babiesReadContract.totalSupply)
  //     if (babiesReadContract && babiesReadContract.totalSupply && babiesReadContract.calculatePriceForToken && babiesReadContract.MAX_BABIES) {
  //       try {
  //         const totalSupply = await babiesReadContract.totalSupply();
  //         const maximumBabies = await babiesReadContract.MAX_BABIES();
  //         const hasSaleStarted = await babiesReadContract.hasSaleStarted();

  //         let currentPrice = 0;
  //         if (totalSupply < maximumBabies - 1) {
  //           currentPrice = await babiesReadContract.calculatePriceForToken(Number(totalSupply.toNumber()));
  //         }
          
  //         setContractInfo({
  //           totalSupply: totalSupply.toNumber(),
  //           currentPrice: ethers.utils.formatUnits(currentPrice),
  //           hasSaleStarted,
  //           maximumBabies: maximumBabies.toNumber() - 1
  //         })
  //       } catch(e) {
  //         console.error(e.message);
  //       }
  //     }
  //   };
  //   query();

  //   // let interval;
  //   // if (babiesReadContract && !interval) {
  //   //   interval = setInterval(() => {
  //   //     query();
  //   //   }, 15000);
  //   // }
  //   // return () => clearInterval(interval);

  // }, [babiesReadContract, startSales]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" mobile={mobile} startGiveaways={startGiveaways} startSales={startSales} component={Home} layout={LayoutDefault} />
          {/* { startGiveaways && <AppRoute exact path="/giveaways" startGiveaways={startGiveaways} component={Giveaway} layout={LayoutDefault} /> } */}
          {/* <AppRoute exact path="/faqs" startGiveaways={startGiveaways} layout={LayoutDefault} component={FAQs}  /> */}
          <AppRoute exact path="/terms" startGiveaways={startGiveaways} component={Terms} layout={LayoutDefault} />
          <AppRoute exact path="/about" component={About} layout={LayoutDefault} />
          {/* <AppRoute exact path="/roadmap" component={Roadmap} layout={LayoutDefault} /> */}
          {/* <AppRoute exact path="/owners" startGiveaways={startGiveaways} startSales={startSales} component={Owners} layout={LayoutDefault} /> */}
        </Switch>
      )} />
  );
}

export default App;