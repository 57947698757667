import React from 'react';
import AboutRocBabies from '../components/sections/AboutRocBabies';
import Footer from '../components/layout/Footer';
import { NotificationContainer } from 'react-notifications';
import AppContext from '../AppContext';

const About = (props) => {
  const { mobile, startGiveaways, contractInfo, glitch } = props;
  return (
    <AppContext.Provider value={{
      contractInfo,
      glitch
    }}>
      <NotificationContainer/>
      <AboutRocBabies mobile={mobile} startGiveaways={startGiveaways} invertMobile imageFill className="what-is-rocbabies" />
      <Footer />
    </AppContext.Provider>
  );
}

export default About;