import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import numbersJson from '../../assets/json/numbers.json';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AboutRocBabies = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  intl,
  ...props
}) => {
  const [rarityModalActive, setRarityModalActive] = useState(false);
  
  const closeModal = (e) => {
    e.preventDefault();
    setRarityModalActive(false);
  }

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={{
            title: intl.formatMessage({ id: 'aboutRoc' })
          }} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div> */}
                <h3 className="mt-0 mb-12">
                  <FormattedHTMLMessage id="about1.title" />
                </h3>
                <p className="m-0">
                  <FormattedHTMLMessage id="about1.desc" />
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <div className="feature-images">
                  <p>
                    <Image
                      src={require('./../../assets/images/featured/roc1.gif')}
                      alt="Doge baby in space"
                      width={180}
                      height={180} />
                      {/* <FormattedHTMLMessage id="about1.image1" /> */}
                    </p>
                    <p>
                      <Image
                        src={require('./../../assets/images/featured/roc2.gif')}
                        alt="Sexy mohawk Ninja shows up in Tokyo, Shibuya"
                        width={180}
                        height={180} />
                      {/* <FormattedHTMLMessage id="about1.image2" /> */}
                    </p>
                </div>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  <FormattedHTMLMessage id="about2.title" />
                  </h3>
                <p className="m-0">
                  <FormattedHTMLMessage id="about2.desc" />
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <div className="feature-images">
                  <p>
                    <Image
                      src={require('./../../assets/images/featured/part1.png')}
                      width={180}
                      height={180} />
                  </p>
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part2.png')}
                      width={180}
                      height={180} />
                  </p>
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part3.png')}
                      width={180}
                      height={180} />
                  </p>
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part4.png')}
                      width={180}
                      height={180} />
                  </p>
                </div>
                <div className="feature-images">
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part5.png')}
                      width={180}
                      height={180} />
                  </p>
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part6.png')}
                      width={180}
                      height={180} />
                  </p>
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part7.png')}
                      width={180}
                      height={180} />
                  </p>
                  <p className="with-background">
                    <Image
                      src={require('./../../assets/images/featured/part8.png')}
                      width={180}
                      height={180} />
                  </p>
                </div>
              </div>
            </div>

            {/* <div id="rarity-section" className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  <FormattedHTMLMessage id="about3.title" />
                  </h3>
                <p className="m-0">
                  <FormattedHTMLMessage id="about3.desc" />
                </p>
                <p id="rarity-table">
                  <Button size="m" onClick={() => {
                    setRarityModalActive(true)
                  }}><FormattedHTMLMessage id="rarity-button" /></Button>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/featured/collage.gif')}
                  alt="Features split 03"
                  width={300}
                  height={300} />
              </div>
            </div> */}

            <div className="split-item-content center-content-mobile reveal-from-right mb-80">
                <h3 className="mt-0 mb-12">
                  <FormattedHTMLMessage id="about4.title" />
                </h3>
                <p className="m-0 doodle-image">
                  <Image
                    src={require('./../../assets/images/doodle.jpg')}
                    alt="Doodle"
                    width={200}
                    height={200} />
                  <FormattedHTMLMessage id="about4.desc" />
                </p>
              </div>
              
            <div className="mb-80">
              <h4><FormattedHTMLMessage id="aboutus.title" /></h4>
              <div>
                <p><FormattedHTMLMessage id="aboutus.desc" /></p>
              </div>
              <div className="about-us reveal-from-bottom">
                <p>
                  <Image
                    src={require('./../../assets/images/babies/rocdad.gif')}
                    alt="Roc Dad"
                    width={120}
                    height={120} />
                    <FormattedHTMLMessage id="aboutus.rocdad" />
                  </p>
                  <p>
                    <Image
                      src={require('./../../assets/images/babies/rocuncle.gif')}
                      alt="Roc Uncle"
                      width={120}
                      height={120} />
                    <FormattedHTMLMessage id="aboutus.rocuncle" />
                  </p>
                  <p>
                    <Image
                      src={require('./../../assets/images/babies/rocbaby.gif')}
                      alt="Roc Baby"
                      width={120}
                      height={120} />
                    <FormattedHTMLMessage id="aboutus.rocbaby" />
                  </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Modal
        id="rarity-table"
        show={rarityModalActive}
        handleClose={closeModal}
        >
          <h5><FormattedHTMLMessage id="rarity" /></h5>
          <div>
            <table>
              {Object.entries(numbersJson).map((e1) => (
                <>
                  <tr>
                    <th colSpan="2">{e1[0]}</th>
                  </tr>
                  <tr>
                    <td className={`rarity-category ${e1[0].toLowerCase()}`}>
                      <Image
                        src={require(`./../../assets/images/rarity/${e1[0].toLowerCase()}.gif`)}
                        height="100"
                      />
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>
      </Modal>

    </section>
  );
}

AboutRocBabies.propTypes = propTypes;
AboutRocBabies.defaultProps = defaultProps;

export default injectIntl(AboutRocBabies);