import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  intl,
  startSales,
  startGiveaways,
  mobile,
  ...props
}) => {

  // Connect to Ethereum
  // useEffect(() => {
  //   const init = async () => {
  //     if (!startSales) return;

  //     // Babies Contract
  //     try {
  //       const { babiesContract, isWrongNetwork } = await getContract();
  //       if (babiesContract) {
  //         setBabiesWriteContract(babiesContract);
  //       }
  //       setWrongNetwork(isWrongNetwork);
  //     } catch(e) {
  //       console.error(e.message);
  //     }
  //   };
  //   init();
  // }, [startSales]);
  
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  // const innerClasses = classNames(
  //   'hero-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider'
  // );

  return (
    <>
      <section
        {...props}
        className={outerClasses}
      >
        {/* {startGiveaways && <div className="campaign-pane">
          <Button className="giveawayButton" size="sm" onClick={() => { window.location.href = "/giveaways" }}>
            <h5><FormattedHTMLMessage id="giveaway.title" /></h5>
            <Image
              className="has-shadow"
              src={require(`../../assets/images/giveaways/Campaign-3.gif`)}
              alt="Campaign Baby"
              width={100}
              height={100} />
          </Button>
        </div> } */}

        <div className="container">
          <div>
            <div className="hero-content-custom">
              <div className="container-sm mr-32">
                <p className="mt-16 mb-32 caption">
                  <FormattedHTMLMessage id="caption" />
                </p>
                <div className="info">
                  {/* <ul>
                    <li>We will be listing our RocBabies on Opensea</li>
                  </ul> */}
                  {/* <p><FormattedHTMLMessage id="home.desc" /></p> */}
                  {/* <ul> */}
                    {/* <li><FormattedHTMLMessage id="home.listing" /></li> */}
                    {/* <li><FormattedHTMLMessage id="home.sale" /></li> */}
                    {/* <li><FormattedHTMLMessage id="home.price" /></li> */}
                  {/* </ul> */}
                  <Button color="primary" size="m" onClick={() => window.open('https://opensea.io/collection/rocbabies')}>
                    <FormattedHTMLMessage id="home.check" />
                  </Button>
                </div>
                {/* <ul className="note">
                  <li><FormattedHTMLMessage id="home.w1" /></li>
                  <li><FormattedHTMLMessage id="home.w2" /></li>
                  <li><FormattedHTMLMessage id="home.w3" /></li>
                  <li><FormattedHTMLMessage id="home.w4" /></li>
                </ul> */}
              </div>
              <div className="container-sm features">
                <div className="features-inner">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/1.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/roc1.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/2.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/roc2.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/9.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/8.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/7.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/6.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/featured/5.gif')}
                    alt="R1"
                    width={120}
                    height={120} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default injectIntl(Hero);