import React, { useEffect, useState } from 'react';
import {IntlProvider} from 'react-intl'
import translation from './translation';
import Header from '../components/layout/Header';
import queryString from 'query-string';

const LayoutDefault = ({ startSales, startGiveaways, children }) => {
  const [lang, setLang] = useState('en');

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    
    const suppoertedLang = ['ja', 'en'];
    let lang = parsed.lang;
    if (!lang) {
      if (localStorage) {
        lang = localStorage.getItem('lang');
      }
    }
    if (!lang) {
      lang = (navigator.language && navigator.language.split(/[-_]/)[0]) || 'en';
    }
    if (suppoertedLang.includes(lang)) {
      setLang(lang);
    } else {
      setLang('en');
    }
  }, [])

  return (
    <IntlProvider messages={translation[lang]} locale="en" defaultLocale="en">
      <Header lang={lang} startGiveaways={startGiveaways} startSales={startSales} navPosition="right" className="reveal-from-bottom" setLang={(l) => {
        localStorage.setItem('lang', l);
        setLang(l)
      }} />
      <main className="site-content">
        {children}
      </main>
    </IntlProvider>
  )
};

export default LayoutDefault;  