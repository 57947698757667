import React from 'react';
import { css } from 'emotion';
import withStyles from 'withstyles';
import PropTypes from 'prop-types';
import extractDomain from 'extract-domain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import Youtube from './icons/Youtube'
import Instagram from './icons/Instagram'
import Tumblr from './icons/Tumblr'
import Github from './icons/Github'
import LinkedIn from './icons/LinkedIn';
import Discord from './icons/Discord';

const getIcon = domain => {
  switch (domain) {
    case 'linkedin.com':
      return LinkedIn
    case 'facebook.com':
      return Facebook;
    case 'twitter.com':
      return Twitter;
    case 'youtube.com':
      return Youtube;
    case 'instagram.com':
      return Instagram;
    case 'tumblr.com':
      return Tumblr;
    case 'github.com':
      return Github;
    case 'discord.gg':
      return Discord;
    default:
      return;
  }
};

const styles = ({ color, hoverColor, hoverMove, spacing }) => ({
  socialContainer: css`
  `,

  social: css`
    margin: ${spacing ? `0px ${spacing}` : '0 0.8rem'};
    transition: transform 250ms;
    display: inline-block;
    opacity: ${color && !hoverColor && '0.8'};
    &:hover {
      color: ${hoverColor};
      opacity: 1;
      transform: ${(hoverMove===undefined && 'translateY(-2px)') || (hoverMove ? 'translateY(-2px)' : '')};
    }
  `,
  'facebook.com': css`
    color: ${color || '#4968ad'};
  `,

  'discord.gg': css`
    color: ${color || '#4968ad'};
    margin-bottom: -4px
  `,

  'youtube.com': css`
    color: ${color || '#eb3223'};
  `,

  'twitter.com': css`
    color: ${color || '#49a1eb'};
  `,

  'instagram.com': css`
    color: ${color || 'black'};
  `,

  'tumblr.com': css`
    color: ${color || '#35465c'};
  `,

  'github.com': css`
    color: ${color || 'black'};
  `,

  'linkedin.com': css`
    color: ${color || '#0e76a8'};
  `,

});

const FollowAt = props => {
  const { computedStyles, links, iconSize } = props;
  return (
    <div className={computedStyles.socialContainer}>
      {links.map((link) => {
        let socialMedia = String(extractDomain(link)).toLowerCase();
        let icon = getIcon(socialMedia);
        return (
          <a
            key={socialMedia}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={`${computedStyles.social} ${
              computedStyles[socialMedia]
            }`}
          >
            {socialMedia !== 'discord.gg' ?
              <FontAwesomeIcon icon={icon} size={`${iconSize}x`} />
            :
              <svg width="40px" height="40px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><title>Discord icon</title><path d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z"/></svg>}
          </a>
        );
      })}
    </div>
  );
};

FollowAt.propTypes = {
  links: PropTypes.array.isRequired,
  iconSize: PropTypes.number,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverMove: PropTypes.bool,
  spacing: PropTypes.string,
};

FollowAt.defaultProps = {
  iconSize: 2,
  hoverMove: true,
};

export default withStyles(styles)(FollowAt);
