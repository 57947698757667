import React from 'react';
import Hero from '../components/sections/Hero';
import Footer from '../components/layout/Footer';
import { NotificationContainer } from 'react-notifications';
import AppContext from '../AppContext';


const Home = (props) => {
  const { mobile, startSales, startGiveaways, contractInfo, babiesReadContract, glitch } = props;
  return (
    <AppContext.Provider value={{
      contractInfo,
      glitch
    }}>
      <NotificationContainer/>
      <Hero mobile={mobile} startGiveaways={startGiveaways} startSales={startSales} babiesReadContract={babiesReadContract} className="illustration-section-01" />
      <Footer />
    </AppContext.Provider>
  );
}

export default Home;